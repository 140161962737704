import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CmsConfig, provideConfig } from "@spartacus/core";
import { CustomParagraphComponent } from "./custom-paragraph.component";
import { SupplementHashAnchorsModule } from "@spartacus/storefront";
import { SafeHtmlModule } from "../../pipes/safe-html/safe-html.module";

@NgModule({
    imports: [
        CommonModule,
        SupplementHashAnchorsModule,
        SafeHtmlModule
    ],
  declarations: [ CustomParagraphComponent ],
  providers: [
    provideConfig({
      cmsComponents: {
        CMSParagraphComponent: {
          component: CustomParagraphComponent,
        },
      }
    } as CmsConfig)
  ]
})
export class CustomParagraphModule {

}
