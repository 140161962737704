import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, OnDestroy} from '@angular/core';
import { FacetListComponent, FacetService } from '@spartacus/storefront';
import { RoutingService } from "@spartacus/core";
import { map, tap } from "rxjs/operators";
import { Facet, FacetValue } from '../custom-facet/custom-facet.types';
import { Subscription } from "rxjs";

@Component({
  selector: 'cx-facet-list',
  templateUrl: './custom-facet-list.component.html',
  styleUrls: ['./custom-facet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFacetListComponent extends FacetListComponent implements OnDestroy {
  /**
   * TODO: Revisar e resolver esta questão de facets.
   */
  originalState: string = '';
  url$ = this.routingService.getRouterState().pipe(
    map((urlState) => {
      this.originalState = urlState.state.url;
      let modifiedUrl = this.originalState;
      const queryStringIndex = modifiedUrl.indexOf('?');
      if (queryStringIndex !== -1) {
        modifiedUrl = modifiedUrl.slice(0, queryStringIndex);
      }
      modifiedUrl = modifiedUrl
        .split('/')
        .filter((p) => p !== 'covercraft' && p !== 'USD' && p !== 'en')
        ?.join('/');

      modifiedUrl = modifiedUrl.replace(/%20/g, ' ');
      return modifiedUrl;
    })
  );
  activeFacetsNoLongerShowing: any[] = [];
  private subscription = new Subscription();

  constructor(
    facetService: FacetService,
    elementRef: ElementRef,
    renderer: Renderer2,
    private routingService: RoutingService
  ) {
    super(facetService, elementRef, renderer);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.subscription.add(
      this.facetService.facetList$.subscribe(list => {
        if (list.facets && list.activeFacets) {
          this.activeFacetsNoLongerShowing = [];
          for (let i=0; i<list.activeFacets.length; i++) {
            let aF = list.activeFacets[i];
            let shownFacet = false;
            if (aF?.facetCode) {
              for (let j = 0; j < list.facets.length; j++) {
                let f: Facet = list.facets[j];
                if (f.code === aF.facetCode) {
                  shownFacet = true;
                }
              }
              if (!shownFacet && !this.containsFacet(aF)) {
                let tempValues = [
                  {
                    code: aF.facetValueCode,
                    name: aF.facetValueName,
                    query: aF.removeQuery
                  }
                ];
                let tempFacet = {
                  code: aF.facetCode,
                  name: aF.facetName,
                  topValueCount: 0,
                  values: tempValues
                }
                this.activeFacetsNoLongerShowing.push(tempFacet);
              }
            }
          }
        }
        if (list.activeFacets?.length === 0) {
          this.activeFacetsNoLongerShowing = [];
        }
      })
    );
  }

  containsFacet(aF: any) {
    let contained = false;
    for (let i=0; i < this.activeFacetsNoLongerShowing.length; i++) {
      if (this.activeFacetsNoLongerShowing[i].code !== aF.facetCode) {
        contained = false;
      }
      else {
        contained = true
      }
    }
    return contained;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }
}
