import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { FacetComponent, FacetService } from '@spartacus/storefront';
import { Facet } from './custom-facet.types';
import { CmsService, FacetValue, RoutingService } from "@spartacus/core";
import { take } from "rxjs/operators";
import { map } from "rxjs/operators";

@Component({
  selector: 'cx-facet',
  templateUrl: './custom-facet.component.html',
  styleUrls: ['./custom-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CustomFacetComponent extends FacetComponent {
  isRatingRange: boolean = false;
  isCommonColor: boolean = false;
  isYmmLevelOne : boolean = false;
  ratingsNumbers: number[] = [];
  colorsImages: { [name: string]: string } = {};
  facetValues: FacetValue[] = [];
  activeFacets: any[] = [];
  @Input() url: string;
  @Input() activeFacet: any;
  @Input() hiddenFacet: any;

  url$ = this.routingService.getRouterState().pipe(
    map((urlState) => {
      //this.originalState = urlState.state.url;
      let modifiedUrl = urlState.state.url;
      const queryStringIndex = modifiedUrl.indexOf('?');
      if (queryStringIndex !== -1) {
        modifiedUrl = modifiedUrl.slice(0, queryStringIndex);
      }
      modifiedUrl = modifiedUrl
        .split('/')
        .filter((p: any) => p !== 'covercraft' && p !== 'USD' && p !== 'en')
        ?.join('/');

      modifiedUrl = modifiedUrl.replace(/%20/g, ' ');
      modifiedUrl = decodeURIComponent( modifiedUrl);
      return modifiedUrl;
    })
  );

  constructor(
    protected cmsService: CmsService,
    facetService: FacetService,
    elementRef: ElementRef<HTMLElement>,
    cd: ChangeDetectorRef,
    private routingService: RoutingService
  ) {
    super(facetService, elementRef, cd);
  }

  // TODO: Refactory
  ngOnInit(): void {
    this.url = decodeURIComponent(this.url);
    this.isRatingRange = this.facet?.name == 'Review Rating Range';
    this.isCommonColor = this.facet?.name == 'Color';
    if (this.activeFacet?.length > 0) {
      this.activeFacet.forEach((activef: any) => {
        if (this.facet?.code === activef.facetCode) {
          this.activeFacets.push(activef);
        }
      });
    }

    this.cmsService.getCurrentPage().pipe(take(1)).subscribe(page => {
      if (page.template === 'YMMCategoryPageTemplate' && page.label === 'shop' && this.facet?.code === 'levelOneCategories') {
        this.isYmmLevelOne = true;
      }
    })

    if (this.isRatingRange) {
      this.facet?.values?.forEach((value, index) => {
        const newValue = {...value};
        const firstNumber = /[0-9]/.exec(value.name);
        if (firstNumber?.length) {
          newValue.rating = +firstNumber[0];
        }
        if (value.name) {
          const number = Number(value.name?.at(0));
          this.ratingsNumbers[index] = !Number.isNaN(number) ? number : 1;
        }
        this.facetValues.push(newValue);
      });
    }
  }

  getLevelOneUrl (value: FacetValue) {
    let cName = value.code;
    return '/' + cName;
  }

  @Input()
  override set facet(value: Facet) {
    this._facet = value;
    this.isMultiSelect = !!value.multiSelect;
    this.state$ = this.facetService.getState(value);
  }

  getLParams(facet: any) {
    return this.facetService.getLinkParams(facet.removeQuery?.query?.value ?? '');
  }

  getHiddenFacetLParams(facet: any) {
    return this.facetService.getLinkParams(facet.query?.query?.value ?? '');
  }

  override get facet(): Facet {
    return this._facet;
  }

  toggleHiddenFacetGroup(event: any) {
    const host = this.elementRef.nativeElement;
    const isLocked = this.keyboardFocus?.isLocked;
    this.facetService.toggle(this.hiddenFacet, this.isExpanded);
    if (!isLocked || this.isExpanded) {
        host.focus();
        // we stop propagating the event as otherwise the focus on the host will trigger
        // an unlock event from the LockFocus directive.
        event.stopPropagation();
    }
  }

}
