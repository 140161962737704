
import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';

export const translations: TranslationResources = {
  en
};


export const translationChunksConfig: TranslationChunksConfig = {
  common: [
    'common',
    'spinner',
    'searchBox',
    'navigation',
    'sorting',
    'httpHandlers',
    'pageMetaResolver',
    'miniCart',
    'skipLink',
    'formErrors',
    'errorHandlers',
    'carousel',
    'assistiveMessage',
    'passwordVisibility',
    'generalErrors',
    'chatMessaging',
    'formValidations'
  ],
  address: [
    'addressForm',
    'addressBook',
    'addressCard',
    'addressSuggestion',
    'addressMessages',
  ],
  payment: [
    'paymentForm',
    'paymentMethods',
    'paymentCard',
    'paymentTypes',
    'paymentMessages',
  ],
  myAccount: [
    'title',
    'orderDetails',
    'orderHistory',
    'closeAccount',
    'updatePasswordForm',
    'updateProfileForm',
    'consentManagementForm',
    'myCoupons',
    'notificationPreference',
    'myInterests',
    'AccountOrderHistoryTabContainer',
    'returnRequestList',
    'returnRequest',
  ],
  pwa: ['pwa'],
  product: [
    'productDetails',
    'productList',
    'productFacetNavigation',
    'productCarousel',
    'productVariantColor',
    'productVariant',
    'productSummary',
    'productReview',
    'productConfiguration',
    'addToCart',
    'addToWishList',
    'CMSTabParagraphContainer',
    'stockNotification',
    'TabPanelContainer',
    'itemCounter',
    'productView',
  ],
  user: ['anonymousConsents', 'loginRegister', 'checkoutLogin', 'authMessages'],
  video: ['player'],
  translation: [
    'generic',
    'customUpdateProfileForm',
    'customUpdatePassword',
    'customUpdateEmail',
    'customAddressForm',
    'customLoginForm',
    'customRegistration',
    'customResetPassword',
    'customForgottenPassword',
    'myGarage',
    'yearMakeModel',
    'ymmCategoryPage',
    'newsletter',
    'productDescription',
    'productKeyFeatures',
    'productInstallation',
    'reviewCheckout',
    'shippingMethod',
    'deliveryMethod',
    'customCheckout',
    'customOrderHistory',
    'additionalDetails',
    'fitmentDetails',
    'talkToUs',
    'checkoutReview',
  ],
};
