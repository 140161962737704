<ng-container *ngIf="!hiddenFacet">
  <div class="cx-facet">
    <button
      class="heading"
      (click)="toggleGroup($event)"
      [attr.aria-expanded]="isExpanded"
      [attr.aria-label]="
        'productFacetNavigation.filterBy.name' | cxTranslate: { name: facet.name }
      "
    >
      {{ facet.name }}
      <cx-icon class="collapse-icon" [type]="collapseIcon"></cx-icon>
      <cx-icon class="expand-icon" [type]="expandIcon"></cx-icon>
    </button>


    <ng-container *ngIf="activeFacets.length > 0 && !isCommonColor">
      <div class="cx-facet-items active-facet-items">
        <a
          *ngFor="let value of activeFacets"
          #facetValue
          [queryParams]="getLParams(value)"
          [routerLink]="url$ | async"
          class="value checkbox"
          [cxFocus]="{ key: value.facetValueName }"
          rel="nofollow"
          attr.aria-label="{{
            'productFacetNavigation.ariaLabelItemsAvailable'
              | cxTranslate: { name: value.facetValueName, count: value.count }
          }}"
        >
          <input type="checkbox" class="mobile-checkbox" checked="true"/>
          <span aria-hidden="true">
            {{ value.facetValueName }}
          </span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="!isRatingRange && !isCommonColor && !isYmmLevelOne">
      <div class="cx-facet-items">
        <a
          *ngFor="let value of facet.values"
          #facetValue
          [routerLink]="url"
          [queryParams]="getLinkParams(value)"
          class="value checkbox"
          [class.selected]="value.selected"
          [cxFocus]="{ key: value.name }"
          rel="nofollow"
          (keydown.space)="openLink($any($event))"
          [attr.role]="facet.multiSelect ? 'checkbox' : null"
          [attr.aria-checked]="facet.multiSelect ? value.selected : null"
          attr.aria-label="{{
            'productFacetNavigation.ariaLabelItemsAvailable'
              | cxTranslate: { name: value.name, count: value.count }
          }}"
        >
          <input type="checkbox" class="mobile-checkbox"/>
          <span aria-hidden="true">
            {{ value.name }}<span aria-hidden="true" class="count">{{ value.count }}</span>
          </span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="isYmmLevelOne">
      <div class="cx-facet-items">
        <a
          *ngFor="let value of facet.values"
          #facetValue
          [routerLink]="url+getLevelOneUrl(value)"
          class="value checkbox"
          [class.selected]="value.selected"
          [cxFocus]="{ key: value.name }"
          rel="nofollow"
          (keydown.space)="openLink($any($event))"
          [attr.role]="facet.multiSelect ? 'checkbox' : null"
          [attr.aria-checked]="facet.multiSelect ? value.selected : null"
          attr.aria-label="{{
            'productFacetNavigation.ariaLabelItemsAvailable'
              | cxTranslate: { name: value.name, count: value.count }
          }}"
        >
          <input type="checkbox" class="mobile-checkbox"/>
          <span aria-hidden="true">
            {{ value.name }}<span aria-hidden="true" class="count">{{ value.count }}</span>
          </span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="isRatingRange">
      <div class="cx-facet-items">
        <a
          *ngFor="let value of facetValues; let i=index"
          #facetValue
          [routerLink]="url"
          [queryParams]="getLinkParams(value)"
          class="value rating"
          [class.selected]="value.selected"
          [cxFocus]="{ key: value.name }"
          rel="nofollow"
          (keydown.space)="openLink($any($event))"
          [attr.role]="facet.multiSelect ? 'checkbox' : null"
          [attr.aria-checked]="facet.multiSelect ? value.selected : null"
          attr.aria-label="{{
            'productFacetNavigation.ariaLabelItemsAvailable'
              | cxTranslate: { name: value.name, count: value.count }
          }}"
        >
          <cx-star-rating [rating]="value.rating"></cx-star-rating>

          <span aria-hidden="true" *ngIf="ratingsNumbers[i] == 5">
            {{ 'productFacetNavigation.only' | cxTranslate: { name: value.name, count: value.count } }}
          </span>

          <span aria-hidden="true" *ngIf="ratingsNumbers[i] < 5">
            {{ 'productFacetNavigation.andUp' | cxTranslate: { name: value.name, count: value.count } }}
          </span>

          <span aria-hidden="true">({{ value.count }})</span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="isCommonColor">
      <div class="cx-facet-items">
        <div class="cx-facet-colors">
          <a
            *ngFor="let value of facet.values; let i=index"
            #facetValue
            [routerLink]="url"
            [queryParams]="getLinkParams(value)"
            rel="nofollow"
            class="value color"
            [class.selected]="value.selected"
            [cxFocus]="{ key: value.name }"
            (keydown.space)="openLink($any($event))"
            [attr.role]="facet.multiSelect ? 'checkbox' : null"
            [attr.aria-checked]="facet.multiSelect ? value.selected : null"
            attr.aria-label="{{
              'productFacetNavigation.ariaLabelItemsAvailable'
                | cxTranslate: { name: value.name, count: value.count }
            }}"
          >
            <img
              [attr.src]="value.icon"
              [attr.title]="value.name"
              [attr.alt]="value.name"
            />
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="hiddenFacet">
  <div class="cx-facet hidden-facet">
    <button
      class="heading"
      (click)="toggleHiddenFacetGroup($event)"
      [attr.aria-expanded]="isExpanded"
      [attr.aria-label]="
        'productFacetNavigation.filterBy.name' | cxTranslate: { name: hiddenFacet?.name }
      "
    >
      {{ hiddenFacet?.name }}
      <cx-icon class="collapse-icon" [type]="collapseIcon"></cx-icon>
      <cx-icon class="expand-icon" [type]="expandIcon"></cx-icon>
    </button>
    <div class="cx-facet-items">
      <a
        *ngFor="let value of hiddenFacet?.values"
        #facetValue
        [queryParams]="getHiddenFacetLParams(value)"
        [routerLink]="url$ | async"
        class="value checkbox"
        [cxFocus]="{ key: value.facetValueName }"
        rel="nofollow"
        attr.aria-label="{{
          'productFacetNavigation.ariaLabelItemsAvailable'
            | cxTranslate: { name: value?.name, count: value?.count }
        }}"
      >
        <input type="checkbox" class="mobile-checkbox" checked="true"/>
        <span aria-hidden="true">
          {{ value?.name }}
        </span>
      </a>
    </div>
  </div>
</ng-container>
