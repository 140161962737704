import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  AfterViewInit,
  ElementRef,
  NgZone
} from "@angular/core";
import { CmsComponentData } from "@spartacus/storefront";
import { CmsParagraphComponent } from "@spartacus/core";
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { TurnToService } from 'src/app/spartacus/features/turnto/core/turnto.service';

@Component({
  selector: 'cx-paragraph',
  templateUrl: './custom-paragraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomParagraphComponent implements AfterViewInit {

  @HostListener('click', [ '$event' ])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      const href = element?.getAttribute('href');

      if (href.indexOf('#') >= 0) {
        const urlParts = href.split('#');
        if (!urlParts[ 0 ] || urlParts[ 0 ] === element.ownerDocument.URL) {
          event.preventDefault();
          this.viewportScroller.scrollToAnchor(urlParts[ urlParts.length - 1 ]);
          return;
        }
      }

      const documentHost = element.ownerDocument.URL
        .split('://')[ 1 ]
        .split('/')[ 0 ];

      if (href && documentHost === element.host) {
        event.preventDefault();
        // the angular router requires the path vs. the full URL
        this.router.navigateByUrl(element.pathname);
      }
    }
  }

  constructor(public component: CmsComponentData<CmsParagraphComponent>,
              protected router: Router,
              private viewportScroller: ViewportScroller,
              private ngZone: NgZone,
              private turnToService: TurnToService,
              private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    const ttGalleryDiv = this.el.nativeElement.querySelector('#tt-gallery-row');
    if (ttGalleryDiv) {
      const pageId = this.el.nativeElement.querySelector('#tt-gallery-row').getAttribute('data-pageId');
      const skus = this.el.nativeElement.querySelector('#tt-gallery-row').getAttribute('data-skus');
      if (pageId && skus) {
        this.ngZone.runOutsideAngular(() => {
          this.turnToService.loadUGCTicker(skus, pageId);
        });
      }
    }
  }
}
