<section
  (click)="block($event)"
  (esc)="close($event)"
  *ngIf="(facetList$ | async)?.facets as facets"
  [attr.aria-label]="'productFacetNavigation.filterBy.facet' | cxTranslate"
  [cxFocus]="isDialog ? dialogFocusConfig : {}"
  [tabindex]="-1"
  class="cx-facet-list inner">
  <div class="list-header">
    <h4>
      {{ 'productList.filterBy.label' | cxTranslate }}
    </h4>
    <button
      (click)="close()"
      [attr.aria-label]="'common.close' | cxTranslate"
      class="close"
      type="button">
      <cx-icon [type]="iconTypes.CLOSE" aria-hidden="true"></cx-icon>
    </button>
  </div>

  <!--
      Here we'd like to introduce configurable facet components,
      either by using specific configuration or generic sproutlets
  -->
  <ng-container *ngIf="(facetList$ | async)?.activeFacets as activeFacets">
    <cx-facet
      #facetRef
      *ngFor="let facet of facets"
      [class.collapsed]="isCollapsed(facet) | async"
      [class.expanded]="isExpanded(facet) | async"
      [facet]="facet"
      [activeFacet]="activeFacets"
      [url]="url$ | async"
      attr.aria-label="{{
      'productFacetNavigation.ariaLabelItemsAvailable'
        | cxTranslate
          : {
              name: facet.name,
              count: facet?.values?.length
            }
    }}"
      role="group"
    ></cx-facet>
  </ng-container>
  <ng-container *ngIf="!(facetList$ | async)?.activeFacets as activeFacets">
    <cx-facet
      #facetRef
      *ngFor="let facet of facets"
      [class.collapsed]="isCollapsed(facet) | async"
      [class.expanded]="isExpanded(facet) | async"
      [facet]="facet"
      [activeFacet]="activeFacets"
      [url]="url$ | async"
      attr.aria-label="{{
        'productFacetNavigation.ariaLabelItemsAvailable'
          | cxTranslate
            : {
                name: facet.name,
                count: facet?.values?.length
              }
      }}"
      role="group"
    ></cx-facet>
  </ng-container>
  <ng-container *ngIf="activeFacetsNoLongerShowing.length > 0">

    <ng-container *ngIf="(facetList$ | async)?.activeFacets as activeFacets">
      <cx-facet
        #facetRef
        *ngFor="let facet of activeFacetsNoLongerShowing"
        [class.collapsed]="isCollapsed(facet) | async"
        [class.expanded]="isExpanded(facet) | async"
        [facet]=""
        [activeFacet]="activeFacets"
        [hiddenFacet]="facet"
        [url]="url$ | async"
        role="group"
      ></cx-facet>
    </ng-container>
    <ng-container *ngIf="!(facetList$ | async)?.activeFacets as activeFacets">
      <cx-facet
        #facetRef
        *ngFor="let facet of activeFacetsNoLongerShowing"
        [class.collapsed]="isCollapsed(facet) | async"
        [class.expanded]="isExpanded(facet) | async"
        [facet]=""
        [activeFacet]=""
        [hiddenFacet]="facet"
        [url]="url$ | async"
        role="group"
      ></cx-facet>
    </ng-container>
  </ng-container>
  <div class="apply-button-container">
    <a
      [routerLink]="url$ | async"
      [attr.aria-label]="'common.close' | cxTranslate"
      class="btn btn-block btn-optional-1 filter-buttons"
      type="button">
      {{ 'common.clear' | cxTranslate }}
    </a>
    <button
      (click)="close()"
      [attr.aria-label]="'common.close' | cxTranslate"
      class="btn btn-secondary btn-block apply-button filter-buttons"
      type="button">
      {{ 'common.apply' | cxTranslate }}
    </button>
  </div>
</section>
