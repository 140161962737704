import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, Subscription } from "rxjs";
import { PageMetaService, WindowRef } from "@spartacus/core";
import { PageLayoutService } from "@spartacus/storefront";
import { CustomSeoMetaService } from "../../../../../../core/cms/seo/custom-seo-meta-service";

@Component({
  selector: 'content-page-template',
  templateUrl: './content-page-template.component.html',
})
export class ContentPageTemplateComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  logoImg = 'https://p1api.covercraft.com/medias/sys_master/images/h35/h5d/8841685663774/covercraft-logo-desktop/covercraft-logo-desktop.webp';

  constructor(
    protected pageLayoutService: PageLayoutService,
    protected pageMetaService: PageMetaService,
    protected customSeoMetaService: CustomSeoMetaService,
    protected winRef: WindowRef,
    @Inject(LOCALE_ID) public locale: string,
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      combineLatest([
        this.pageLayoutService.page$,
        this.pageMetaService.getMeta()
      ]).subscribe(([page, meta]) => {
        if (page && page.type === 'ContentPage' && (page.template === 'LandingPage2Template' || page.template === 'ContentPage1Template')) {
          this.customSeoMetaService.setOgTitle(meta.title);
          this.customSeoMetaService.setOgUrl(this.winRef.location.href);
          this.customSeoMetaService.setOgImage(this.logoImg);
          this.customSeoMetaService.setOgType('article');
          this.customSeoMetaService.setOgDescription(page?.metaDescription);
          this.customSeoMetaService.setOgLocale(this.locale);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
